import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "./Top.css";
import L, { LocationEvent } from "leaflet";
import { Button, Container, Image, Navbar, Stack } from "react-bootstrap";
import pinIcon from "../icons/map-pin-fill.svg";
import ClickMaker from "./clickmarker";
import currentPosPin from "../icons/currentPos.png";
import KTV from "./KTV";
import Restaurant from "./Restaurant";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FooterBtnState,
  LanguageSetting,
  RestaurantTagState,
} from "../common/Const";
import Conveni from "./Conveni";
import MoneyExchanger from "./MoneyExchanger";
import BtnFooter from "./BtnFooter";
import PokerRoom from "./PokerRoom";
import Casino from "./Casino";
import LangToggleSwitch from "./LangToggleSwitch";
import Hotel from "./Hotel";
import QRCodeModal from "./QRCodeModal";
import FilterCompo from "./FilterCompo";
import HeaderNav from "./HeaderNav";
import ChangeView from "./ChangeViewCompo";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";

const Top: React.FC = () => {
  const isPhone = navigator.userAgent.match(/iPhone|Android/);
  // const isPhone = navigator.userAgent.match(/iPhone|Android.+Mobile/);
  const [footerBtnState, setFooterBtnState] = useState<FooterBtnState>("KTV");
  const [restaurantTagState, setRestaurantTagState] =
    useState<RestaurantTagState>("");
  const [serchedShopName, setSerchedShopName] = useState<string>("");
  const [langSetting, setLanguage] = useState<LanguageSetting>("JA");
  const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
  const [currentPos, setCurrentPos] = useState<L.LatLngLiteral>();
  const [isCurrentPosShow, setIsCurrentPosShow] = useState<boolean>(false);
  const [isMoveCenter, setIsMoveCenter] = useState<boolean>(false);
  const [isQRModalShow, setIsQRModalShow] = useState<boolean>(false);
  const [isPinnedCurrentPos, setIsPinnedCurrentPos] = useState<boolean>(false);
  const [centerOfMap, setCenterOfMap] = useState<L.LatLngTuple>([
    14.571649689172614, 120.98437441430472,
  ]);
  const [zoomOfMap, setZoomOfMap] = useState<number>(18);
  const [pinId, setPinId] = useState("");
  const [swipeableEdgeDrawerOpen, setSwipeableEdgeDrawerOpen] = useState(false);

  const handleFilterClose = () => {
    setIsFilterShow(false);
    setSerchedShopName("");
  };
  const handleFilterShow = useCallback(
    () => (isFilterShow ? setIsFilterShow(false) : setIsFilterShow(true)),
    [isFilterShow]
  );

  const handleLangChange = useCallback(() => {
    setFooterBtnState("");
    if (langSetting === "JA") {
      setLanguage("EN");
    } else if (langSetting === "EN") {
      setLanguage("JA");
    }
  }, [langSetting]);

  //余計な再レンダリングを防ぐためにメモを作成
  const KTVMemo = useMemo(
    () => <KTV serchedShopName={serchedShopName} langSetting={langSetting} />,
    [serchedShopName, langSetting]
  );
  const restaurantMemo = useMemo(
    () => (
      <Restaurant
        serchedShopName={serchedShopName}
        serchedType={restaurantTagState}
        langSetting={langSetting}
      />
    ),
    [serchedShopName, restaurantTagState, langSetting]
  );
  const hotelMemo = useMemo(
    () => <Hotel serchedShopName={serchedShopName} langSetting={langSetting} />,
    [serchedShopName, langSetting]
  );
  const porkerRoomMemo = useMemo(
    () => (
      <PokerRoom serchedShopName={serchedShopName} langSetting={langSetting} />
    ),
    [serchedShopName, langSetting]
  );
  const casinoMemo = useMemo(
    () => (
      <Casino serchedShopName={serchedShopName} langSetting={langSetting} />
    ),
    [serchedShopName, langSetting]
  );
  const conveniMemo = useMemo(() => <Conveni langSetting={langSetting} />, []);
  const moneyExchangerMemo = useMemo(
    () => <MoneyExchanger langSetting={langSetting} />,
    [langSetting]
  );
  const QRCodeModalMemo = useMemo(
    () => (
      <QRCodeModal
        isQRModalShow={isQRModalShow}
        setIsQRModalShow={setIsQRModalShow}
        langSetting={langSetting}
      />
    ),
    [isQRModalShow, setIsQRModalShow, langSetting]
  );
  const FilterCompoMemo = useMemo(
    () => (
      <FilterCompo
        isFilterShow={isFilterShow}
        handleFilterShow={handleFilterShow}
        langSetting={langSetting}
        serchedShopName={serchedShopName}
        setSerchedShopName={setSerchedShopName}
        setCenterOfMap={setCenterOfMap}
        setIsMoveCenter={setIsMoveCenter}
        setPinId={setPinId}
        pinId={pinId}
        setZoomOfMap={setZoomOfMap}
        footerBtnState={footerBtnState}
      />
    ),
    [
      isFilterShow,
      langSetting,
      serchedShopName,
      setSerchedShopName,
      handleFilterShow,
      pinId,
      footerBtnState,
    ]
  );

  const HeaderNavMemo = useMemo(
    () => (
      <HeaderNav
        langSetting={langSetting}
        setIsQRModalShow={setIsQRModalShow}
        handleLangChange={handleLangChange}
      />
    ),
    [langSetting, handleLangChange]
  );

  const ChangeViewMemo = useMemo(
    () => (
      <ChangeView
        center={centerOfMap}
        zoom={zoomOfMap}
        isMoveCenter={isMoveCenter}
        setIsMoveCenter={setIsMoveCenter}
        pinId={pinId}
      />
    ),
    [centerOfMap, isMoveCenter, zoomOfMap, pinId]
  );

  //現在地表示用ロジック
  // const ChangeView: React.FC<{ center: L.LatLngTuple; zoom: number }> = ({
  //   center,
  //   zoom,
  // }) => {
  //   const map = useMap();
  //   if (isMoveCenter) {
  //     map.setView(center, zoom);
  //     setIsMoveCenter(false);
  //   }
  //   return null;
  // };

  type currentPosProps = {};

  const CurrentPosition: React.FC<currentPosProps> = () => {
    const map = useMap();
    const onLocationFound = (e: LocationEvent) => {
      setCurrentPos(e.latlng);
      setIsPinnedCurrentPos(true);
    };
    useEffect(() => {
      if (!isPinnedCurrentPos && !swipeableEdgeDrawerOpen) {
        map.on("locationfound", onLocationFound);
        map.locate({ watch: true });
      }
    }, [map]);

    // setTimeout(() => {
    //   setIsPinnedCurrentPos(false);
    // }, 1000);

    const icon = L.icon({ iconUrl: currentPosPin, iconSize: [20, 20] });
    return isCurrentPosShow ? (
      <>
        <Marker
          icon={icon}
          position={L.latLng(currentPos ? currentPos : new L.LatLng(0, 0))}
          zIndexOffset={200}
        />
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div className="top">
        {HeaderNavMemo}
        <MapContainer
          center={centerOfMap}
          zoom={17}
          maxZoom={20}
          scrollWheelZoom={true}
          zoomControl={false}
          preferCanvas={true}
          renderer={L.canvas()}
        >
          {/* 現在地表示用 */}
          {/* <ChangeView center={centerOfMap} zoom={18} /> */}
          {ChangeViewMemo}
          {isPhone ? <CurrentPosition /> : <></>}
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={18}
            maxZoom={20}
          />
          {footerBtnState === "KTV" && KTVMemo}
          {footerBtnState === "Restaurant" && restaurantMemo}
          {footerBtnState === "Hotel" && hotelMemo}
          {footerBtnState === "PokerRoom" && porkerRoomMemo}
          {footerBtnState === "Casino" && casinoMemo}
          {footerBtnState === "Conveni" && conveniMemo}
          {footerBtnState === "MoneyExchanger" && moneyExchangerMemo}
          {/* ↓↓↓↓ピンデバッグ用↓↓↓↓ */}
          {/* <ClickMaker /> */}
          {/* ↑↑↑↑ピンデバッグ用↑↑↑↑ */}
          <SwipeableEdgeDrawer
            footerBtnState={footerBtnState}
            setCenterOfMap={setCenterOfMap}
            swipeableEdgeDrawerOpen={swipeableEdgeDrawerOpen}
            setSwipeableEdgeDrawerOpen={setSwipeableEdgeDrawerOpen}
            setIsMoveCenter={setIsMoveCenter}
            setPinId={setPinId}
            setZoomOfMap={setZoomOfMap}
          ></SwipeableEdgeDrawer>
        </MapContainer>
        {/* {footerBtnState === "" || footerBtnState === "Conveni" ? null : (
          // <Button
          //   className="offcanvas-btn pt-1 pb-4"
          //   variant="secondary"
          //   onClick={() => {
          //     const closeBtn = document.querySelector(
          //       `.leaflet-popup-close-button`
          //     ) as HTMLElement;
          //     if (closeBtn) closeBtn.click();
          //     handleFilterShow();
          //   }}
          // >
          //   {isFilterShow
          //     ? langSetting === "JA"
          //       ? "フィルター <"
          //       : "Filter <"
          //     : langSetting === "JA"
          //     ? "フィルター >"
          //     : "Filter >"}
          // </Button>
          <Image
            className="offcanvas-btn pt-1 pb-4"
            src="/iconBtn/searchIcon.png"
            onClick={() => {
              const closeBtn = document.querySelector(
                `.leaflet-popup-close-button`
              ) as HTMLElement;
              if (closeBtn) closeBtn.click();
              handleFilterShow();
            }}
          />
        )} */}
        {isPhone ? (
          // <Button
          //   className="current-position-btn pt-2 pb-4"
          //   variant="secondary"
          //   onClick={() => {
          //     setIsCurrentPosShow(true);
          //     setCenterOfMap(
          //       currentPos ? [currentPos.lat, currentPos?.lng] : [0, 0]
          //     );
          //     setIsMoveCenter(true);
          //   }}
          // >
          //   {/* {langSetting === "JA" ? "現在地" : "CurrentLocation"} */}
          // </Button>
          <Image
            className="current-position-btn pt-2 pb-4"
            src="/iconBtn/moveCurrentPos.png"
            onClick={() => {
              setIsCurrentPosShow(true);
              setCenterOfMap(
                currentPos ? [currentPos.lat, currentPos?.lng] : [0, 0]
              );
              setIsMoveCenter(true);
            }}
          />
        ) : (
          <></>
        )}
        <BtnFooter
          footerBtnState={footerBtnState}
          setSerchedShopName={setSerchedShopName}
          setFooterBtnState={setFooterBtnState}
          langSetting={langSetting}
          setZoomOfMap={setZoomOfMap}
          setIsMoveCenter={setIsMoveCenter}
        ></BtnFooter>
        {FilterCompoMemo}
        {QRCodeModalMemo}
      </div>
    </>
  );
};

export default Top;
